import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {ClickProvider, ClickUI, TopBarSettings} from "@make-software/csprclick-ui";
import { CopyHashMenuItem, ViewAccountOnExplorerMenuItem } from '@make-software/csprclick-ui';
import { ThemeProvider } from 'styled-components';
import {CsprClickThemes, ThemeModeType} from '@make-software/csprclick-ui';

const ClickTopBarWrapper = () => {
  const storedTheme = window.localStorage.getItem("studio-theme") || "dark"
  const [studioTheme, setStudioTheme] = React.useState<ThemeModeType>(storedTheme as ThemeModeType);
  
  function toggleStudioTheme() {
    window.localStorage.setItem("studio-theme", studioTheme === ThemeModeType.dark ? "light" : "dark");
    setStudioTheme(studioTheme === ThemeModeType.dark ? ThemeModeType.light : ThemeModeType.dark);
  }

  useEffect( () => {
    var page = document.querySelector('body');
    if(studioTheme === ThemeModeType.dark)
      page?.classList.add('dark')
    else
      page?.classList.remove('dark')
  }, [studioTheme]);

  const accountMenuItems = [
    <ViewAccountOnExplorerMenuItem key={0} />,
    <CopyHashMenuItem key={1} />,
  ];

  const topBarSettings: TopBarSettings = {
    onThemeSwitch: () => toggleStudioTheme(),
    accountMenuItems,
};

  return (   
    <ThemeProvider theme={CsprClickThemes[studioTheme == 'dark' ? ThemeModeType.dark : ThemeModeType.light]}>
              <section className="top-bar">
                  <div className="container">
                      <div className="row">
                        <ClickUI
                          themeMode={studioTheme == 'dark' ? ThemeModeType.dark : ThemeModeType.light} 
                          topBarSettings={topBarSettings}
                          />
                      </div>
                  </div>
              </section>
      </ThemeProvider>                       
  )
}

// const clickOptions = {
//   appName: "CSPR.studio",
//   appId: "cspr-studio-dev",
//   contentMode: "iframe",
//   iframeContainer: undefined,
//   apiKey: 'csprstudio-api-key-devenv',
//   providers: ['casper-wallet', 'casper-signer'],
//   csprclickHost: 'https://casper-click-static.dev.make.services',
//   casperNode: '',
// };
declare const clickOptions: any;

const root = ReactDOM.createRoot(
  document.getElementById('csprclick-navbar') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ClickProvider options={clickOptions}>
      <ClickTopBarWrapper/>
    </ClickProvider>
  </React.StrictMode>
);
